import React from 'react'
import styled from 'styled-components'

import { useIsPatientProfileLoading, useMissedFields } from 'hooks/patient/useProfile'

import { RegularText } from 'common/texts'

const config = {
  'email': 'Email',
  'first_name': 'First name',
  'last_name': 'Last name',
  'phone': 'Phone',
  'zip': 'ZIP',
  'gender': 'Gender at birth',
  'identify_gender_id': 'Gender identity',
  'address_1': 'Street address 1',
  'state': 'State',
  'city': 'City',
  'birth_date': 'Birth date',
  'emergency.phone': 'Emergency phone',
  'race_ethnicity': 'Race or Ethnicity',
  'language': 'Language',
  'income_range': 'Income range'
}

export default React.memo(function Hint () {
  const isLoading = useIsPatientProfileLoading()
  const missedFields = useMissedFields()

  const missedlabels = React.useMemo(() => {
    return missedFields.map(field => config[field]).join(', ')
  }, [missedFields])

  if (isLoading || missedFields.length === 0) return null

  return (
    <StyledWrapper>
      <RegularText>
        Please add: {missedlabels}
      </RegularText>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  padding: 2rem;
  border-radius: 0.8rem;
  background: #f1f5f8;
`
