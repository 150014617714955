import React from "react";

import { PROFILE } from "constants/routes";

import { useSetPatinetProfile } from "hooks/usePatients";
import { useFetchPatinetProfile } from "requests/patients";

import DetailsTemplate from "components/patients/DetailsTemplate";

import PersonalInformation from "components/patients/profile/PersonalInformation";
import Contactinformation from "components/patients/profile/Contactinformation";
import EmergencyInformation from "components/patients/profile/EmergencyInformation";
import PrimaryProvider from "components/patients/profile/PrimaryProvider";
import LinkToEdit from "./../../../components/patients/profile/LinkToEdit";
import { useUserContext } from "../../../context/userContext";
import { permissions } from "../../../permissions";
import DemographicInformation from "../../../components/patients/profile/DemographicInformation";

export default function PatientProfile() {
  const { can } = useUserContext();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const clearPatinetProfile = useSetPatinetProfile(() => null, []);

  React.useEffect(() => {
    fetchPatinetProfile();
    return clearPatinetProfile;
    // eslint-disable-next-line
  }, []);

  return (
    <DetailsTemplate currentTab={PROFILE}>
      <PersonalInformation />

      <Contactinformation />

      <DemographicInformation />

      <EmergencyInformation />

      <PrimaryProvider />
      {can(permissions.patient.canEditProfile) && <LinkToEdit />}
    </DetailsTemplate>
  );
}
