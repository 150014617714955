import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGenders } from 'hooks/useProfile'
import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients'
import { useIsPatientProfileLoading, VALIDATION_RULES } from 'hooks/patient/useProfile'

import { FlexLine } from 'common/plate/styles'
import { RegularBigText } from 'common/texts'
import InputWithLabel from 'common/InputWithLabel'
import CustomSelect from 'common/CustomSelect'

import BirthDate from './BirthDate'

export default React.memo(function PersonalInformation () {
  const isLoading = useIsPatientProfileLoading()
  const localPatientProfile = useLocalPatientProfile()
  const genders = useGenders() || []
  const firstName = get(localPatientProfile, 'first_name', '')
  const lastName = get(localPatientProfile, 'last_name', '')
  const gender = get(localPatientProfile, 'gender', '')
  const identifyGender = get(localPatientProfile, 'identify_gender_id', '')
  const otherGenderValue = get(localPatientProfile, 'other_gender_value', '')

  const isFirstNameValid = VALIDATION_RULES['first_name'](firstName)
  const isLastNameValid = VALIDATION_RULES['last_name'](lastName)
  const isGenderValid = VALIDATION_RULES['gender'](gender)
  const isIdentifyGenderValid = VALIDATION_RULES['identify_gender_id'](identifyGender)

  const handleFirstNameChange = useSetLocalPatientProfile((prev, firstName) => ({ ...prev, first_name: firstName }), [])
  const handleLastNameChange = useSetLocalPatientProfile((prev, lastName) => ({ ...prev, last_name: lastName }), [])
  const handleGenderChange = useSetLocalPatientProfile((prev, gender) => ({ ...prev, gender }), [])
  const handleIdentifyGenderChange = useSetLocalPatientProfile((prev, identifyGender) => ({ ...prev, identify_gender_id: identifyGender }), [])
  const handleOtherGenderChange = useSetLocalPatientProfile((prev, otherGenderValue) => ({ ...prev, other_gender_value: otherGenderValue }), [])

  return (
    <StyledWrapper>
      <RegularBigText className='g-mt-10'>
        Personal information
      </RegularBigText>
      <FlexLine>
        <InputWithLabel
          className='g-mt-20 g-mr-25'
          label='First name'
          placeholder='Your First name'
          isRequred
          isValid={isFirstNameValid}
          value={firstName}
          changeHandler={handleFirstNameChange}
          isLoading={isLoading}
        />
        <InputWithLabel
          className='g-mt-20'
          label='Last name'
          placeholder='Your Last name'
          isRequred
          isValid={isLastNameValid}
          value={lastName}
          changeHandler={handleLastNameChange}
          isLoading={isLoading}
        />
      </FlexLine>
      <FlexLine>
        <CustomSelect
          className='g-mt-20 g-mr-25'
          label='Assigned sex at birth'
          placeholder='Assigned sex at birth'
          isRequred
          isValid={isGenderValid}
          value={gender}
          options={genders}
          changeHandler={handleGenderChange}
          isLoading={isLoading}
        />
        <BirthDate isLoading={isLoading} />
      </FlexLine>
      <FlexLine>
          <CustomSelect
              className='g-mt-20 g-mr-25'
              label='Gender identity'
              placeholder='Gender identity'
              isRequred
              isValid={isIdentifyGenderValid}
              value={identifyGender}
              options={genders}
              changeHandler={handleIdentifyGenderChange}
              isLoading={isLoading}
          />
          {identifyGender === '3' && (
              <InputWithLabel
                  className='g-mt-20 g-mr-25 empty-label'
                  label=' '
                  placeholder='Your gender identity'
                  value={otherGenderValue}
                  changeHandler={handleOtherGenderChange}
                  isLoading={isLoading}
              />
          )}
      </FlexLine>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .birthday {
    display: flex;
  }
  .year {
    width: 130px;
    margin-right: 1rem;
  }
  .day {
    width: 85px;
    margin-right: 1rem;
    align-self: flex-end;
  }
  .month {
    width: 100px;
    align-self: flex-end;
  }
  .empty-label p {
    height: 17px;
  }
`
