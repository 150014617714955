import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { FlexLine } from 'common/plate/styles'
import { RegularBigText } from 'common/texts'
import InputWithLabel from 'common/InputWithLabel'
import CustomSelect from 'common/CustomSelect'
import {useIsPatientProfileLoading, VALIDATION_RULES} from "../../../hooks/patient/useProfile";
import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients'

export default React.memo(function PersonalInformation () {
    const isLoading = useIsPatientProfileLoading()
    const localPatientProfile = useLocalPatientProfile()

    const otherRaceEthnicity = get(localPatientProfile, 'other_race_ethnicity', '')
    const language = get(localPatientProfile, 'language', '')

    const raceEthnicity = get(localPatientProfile, 'race_ethnicity', '')
    const raceEthnicities = [
        { label: 'American Indian or Alaskan Native', value: 'American Indian or Alaskan Native' },
        { label: 'Asian', value: 'Asian' },
        { label: 'Black or African American', value: 'Black or African American' },
        { label: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander' },
        { label: 'White', value: 'White' },
        { label: 'Other', value: 'Other' },
    ]

    const incomeRange = get(localPatientProfile, 'income_range', '')
    const incomeRanges = [
        { label: '$0-$9,999', value: '$0-$9,999' },
        { label: '$10,000-$24,999', value: '$10,000-$24,999' },
        { label: '$25,000-$49,999', value: '$25,000-$49,999' },
        { label: '$50,000-$74,999', value: '$50,000-$74,999' },
        { label: '$75,000-$99,999', value: '$75,000-$99,999' },
        { label: '$100,000-$149,999', value: '$100,000-$149,999' },
        { label: '$150,000+', value: '$150,000+' },
        { label: 'Prefer not to answer', value: 'Prefer not to answer' },
    ]

    const handleRaceEthnicityChange = useSetLocalPatientProfile((prev, raceEthnicity) => ({ ...prev, race_ethnicity: raceEthnicity }), [])
    const handleOtherRaceEthnicityChange = useSetLocalPatientProfile((prev, otherRaceEthnicity) => ({ ...prev, other_race_ethnicity: otherRaceEthnicity }), [])
    const handleLanguageChange = useSetLocalPatientProfile((prev, language) => ({ ...prev, language }), [])
    const handleIncomeRangeChange = useSetLocalPatientProfile((prev, incomeRange) => ({ ...prev, income_range: incomeRange }), [])

    const isRaceValid = VALIDATION_RULES['race_ethnicity'](raceEthnicity)
    const isOtherRaceValid = VALIDATION_RULES['other_race_ethnicity'](otherRaceEthnicity)
    const isLanguageValid = VALIDATION_RULES['language'](language)
    const isIncomeRangeValid = VALIDATION_RULES['income_range'](incomeRange)

    return (
        <StyledWrapper>
            <RegularBigText className='g-mt-10'>
                Demographic information
            </RegularBigText>
            <FlexLine>
                <CustomSelect
                    className='race g-mt-20 g-mr-25'
                    label='Race or Ethnicity'
                    placeholder='Your Race or Ethnicity'
                    isRequred
                    isValid={isRaceValid}
                    value={raceEthnicity}
                    options={raceEthnicities}
                    changeHandler={handleRaceEthnicityChange}
                    isLoading={isLoading}
                />
                {raceEthnicity === 'Other' && (
                    <InputWithLabel
                        className='other-race g-mt-20 g-mr-25 empty-label'
                        label=' '
                        placeholder='Race or Ethnicity'
                        isValid={isOtherRaceValid}
                        value={otherRaceEthnicity}
                        changeHandler={handleOtherRaceEthnicityChange}
                        isLoading={isLoading}
                    />
                )}
            </FlexLine>
            <FlexLine>
                <InputWithLabel
                    className='g-mt-20 g-mr-25'
                    label='Language'
                    placeholder='Your language'
                    isRequred
                    isValid={isLanguageValid}
                    value={language}
                    changeHandler={handleLanguageChange}
                    isLoading={isLoading}
                />
            </FlexLine>
            <FlexLine>
                <CustomSelect
                    className='g-mt-20 g-mr-25'
                    label='Income range'
                    placeholder='Your income'
                    isRequred
                    isValid={isIncomeRangeValid}
                    value={incomeRange}
                    options={incomeRanges}
                    changeHandler={handleIncomeRangeChange}
                    isLoading={isLoading}
                />
            </FlexLine>
        </StyledWrapper>
    )
})

const StyledWrapper = styled.div`
  .empty-label p {
    height: 17px;
  }
`
