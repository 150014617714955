import React from "react";
import get from "lodash/get";
import moment from "moment";

import { usePatinetProfile } from "hooks/usePatients";

import { MediumText } from "common/texts";
import PropertyValue from "common/PropertyValue";
import ChangePasswordButton from "./change-password-button";
import { permissions } from "../../../permissions";
import { useUserContext } from "../../../context/userContext";

export default function PersonalInformation() {
  const patinetProfile = usePatinetProfile();
  const { can } = useUserContext();

  const isLoading = React.useMemo(() => !patinetProfile, [patinetProfile]);

  const firstName = get(patinetProfile, "first_name", "");
  const lastName = get(patinetProfile, "last_name", "");
  const gender = get(patinetProfile, "gender_id", "");
  const identifyGender = get(patinetProfile, "other_gender_value", "") || get(patinetProfile, "identify_gender_name", "");

  const dateOfBirthday = React.useMemo(() => {
    const birthDate = get(patinetProfile, "birth_date", "");
    if (birthDate === "n/a") return "";
    const parsedBirthDate = moment(birthDate).format("MMMM DD, YYYY");
    const age = moment().diff(birthDate, "years");
    return `${parsedBirthDate} (${age} years)`;
  }, [patinetProfile]);
  const weight = get(patinetProfile, "weight", "");

  return (
    <>
      <MediumText>Personal Information</MediumText>

      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="First name"
        value={firstName}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Last name"
        value={lastName}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Gender"
        value={gender}
      />
        <PropertyValue
            className="g-mt-5"
            isLoading={isLoading}
            property="Gender identity"
            value={identifyGender}
        />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Date of Birthday"
        value={dateOfBirthday}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Weight"
        value={weight}
      />
      {can(permissions.patient.canChangePassword) && (
        <PropertyValue
          className="g-mt-5"
          isLoading={isLoading}
          property="Password"
          value={<ChangePasswordButton />}
        />
      )}
    </>
  );
}
