import React from 'react'

import { PROFILE } from 'constants/routes';

import { useSetPatinetProfile } from 'hooks/usePatients'
import { useFetchPatinetProfile } from 'requests/patients/patients'
import { useFetchGenders } from 'requests/profile'

import DetailsTemplate from 'components/patients/DetailsTemplate'
import Hint from 'components/patients/edit-profile/Hint'
import PersonalInformation from 'components/patients/edit-profile/PersonalInformation'
import ContactInformation from 'components/patients/edit-profile/ContactInformation'
import EmergencyContactInformation from 'components/patients/edit-profile/EmergencyContactInformation'
import PrimaryCareProviderInformation from 'components/patients/edit-profile/PrimaryCareProviderInformation'
import UpdateButton from 'components/patients/edit-profile/UpdateButton'
import DemographicInformation from "../../../components/patients/edit-profile/DemographicInformation";

export default function PatientEditProfile () {
  const fetchGenders = useFetchGenders()
  const fetchPatinetProfile = useFetchPatinetProfile()
  const clearPatinetProfile = useSetPatinetProfile(() => null, [])

  React.useEffect(() => {
    fetchGenders()
    fetchPatinetProfile()
    return clearPatinetProfile
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={PROFILE}>
      <Hint />
      <PersonalInformation />
      <ContactInformation />
      <DemographicInformation />
      <EmergencyContactInformation />
      <PrimaryCareProviderInformation />
      <UpdateButton />
    </DetailsTemplate>
  )
}
