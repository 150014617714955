import React from "react"
import get from "lodash/get"

import {usePatinetProfile} from "hooks/usePatients"

import {MediumText} from "common/texts"
import PropertyValue from "common/PropertyValue"

export default function DemographicInformation() {
    const patientProfile = usePatinetProfile()
    const isLoading = React.useMemo(() => !patientProfile, [patientProfile])
    const raceEthnicity = get(patientProfile, "other_race_ethnicity", "") || get(patientProfile, "race_ethnicity", "")

    const language = get(patientProfile, "language", "")
    const incomeRange = get(patientProfile, "income_range", "")

    return (
        <>
            <MediumText className={'g-mt-30'}>Demographic Information</MediumText>

            <PropertyValue
                className="g-mt-5"
                isLoading={isLoading}
                property="Race or ethnicity"
                value={raceEthnicity}
            />
            <PropertyValue
                className="g-mt-5"
                isLoading={isLoading}
                property="Language"
                value={language}
            />
            <PropertyValue
                className="g-mt-5"
                isLoading={isLoading}
                property="Income range"
                value={incomeRange}
            />
        </>
    )
}
